import React, { useEffect } from "react";
// libs
import styled from "styled-components";
// components
import CustomHead from "src/components/ui/CustomHead";
import Layout from "src/layout";
import Clinics from "src/components/containers/clinics";
// hooks
import { useReviews } from "src/hooks/services";
import { useDynamicClinics } from "src/hooks/dynamic/clinics";
import useAllPage from "src/hooks/dynamic/allPage";
// theme
import { colors } from "src/theme/colors";
// utils
import { getCountry } from "src/utils/countries";
// types
import { GetStaticProps, NextPage } from "next";
import { IReview } from "src/types";

const Wrapper = styled.div`
  background-color: ${colors.main};
  overflow: hidden;
`;

interface IClinicsPage {
  reviews: IReview[];
}

const ClinicsPage: NextPage<IClinicsPage> = ({ reviews }) => {
  useEffect(() => {
    if (!localStorage.getItem("pa-visitorType")) {
      localStorage.setItem("pa-visitorType", "vet");
    }
  }, []);

  return (
    <>
      <CustomHead />

      <Wrapper>
        <Layout headerProps={{ styles: { width: "1096px" } }}>
          <Clinics reviews={reviews} />
        </Layout>
      </Wrapper>
    </>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { loadReviews } = useReviews();

  const userCountry = getCountry(ctx.locale, "locale")?.name;

  const reviews = await loadReviews(
    `_sort=created_at:desc&_limit=20&rating_gte=4&clinic.country=${userCountry}`,
  );
  const initialReduxState = await useAllPage(ctx.locale as string);

  return {
    props: {
      initialReduxState: { DYNAMIC: initialReduxState.DYNAMIC },
      reviews,
    },
    revalidate: 10, // 10 Second Cache
  };
};

export default ClinicsPage;
